<template>
  <div class="light">
    <v-container>
      <v-col v-if="loading === false" cols="12">
        <v-alert type="error"> DASHBOARD IS LOADING </v-alert></v-col
      >
      <v-col cols="12">
        <v-card class="pa-3 purple darken-1">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Investor Portal
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="status == 'offline'" cols="12">
        <v-alert type="error" v-on:click="showTaC">
          A hotspot is offline. Click here for suggestions on how to
          troubleshoot.
        </v-alert></v-col
      >
      <v-dialog v-model="signneed" persistent max-width="450">
        <v-card>
          <v-card-title class="text-h5">
            New contract awaiting signature.
          </v-card-title>
          <v-card-text
            >A new hotspot has been assigned to you. Please sign the contract to
            see the hotspot details.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="purple darken-1" text @click="signneed = false">
              Close
            </v-btn>
            <v-btn color="purple darken-1" text to="/sign"> Go to sign </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col v-if="notamonth === false" cols="12">
        <v-alert type="warning">
          You will start earning rewards on {{ hname }} (30 days after install)
        </v-alert></v-col
      >
      <v-col v-if="daterror === false" cols="12">
        <v-alert type="error">
          Start and End date can not be same. To View 1 day date , please select
          end as next date
          <h1></h1> </v-alert
      ></v-col>
      <v-col v-if="datareverse === false" cols="12">
        <v-alert type="error">
          Enter a valid data range
          <h1></h1> </v-alert
      ></v-col>
      <v-row md="6" cols="12" class="pa-3">
        <v-col md="5" cols="12">
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Total Invested (USD)
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  ${{ changeNumberFormat(total) }}
                </v-card-title>
                <div v-for="item in investDet" :key="item.ID">
                  <v-card-subtitle class="pb-0">
                    {{ item.name }}
                  </v-card-subtitle>
                  <v-card-title class="text-h6 gold_4--text">
                    ${{ changeNumberFormat(item.amount) }}
                  </v-card-title>
                </div>
              </div>
            </div>
          </v-card>
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Total Paid Out (USD)
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  ${{ changeNumberFormat(paidouttotal) }}
                </v-card-title>
              </div>
            </div>
          </v-card>
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Number of Hotspots
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  {{ changeNumberFormat(hotspotcount) }}
                </v-card-title>
              </div>
            </div>
          </v-card>
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0">
                  Next Payout Date
                </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  {{ monthfirstDay.toLocaleDateString() }}</v-card-title
                >
                <div v-for="(item, investname) in tier" :key="item.name">
                  <v-card-subtitle class="pb-0">
                    {{ investname }}
                  </v-card-subtitle>
                  <v-card-title class="text-h6 gold_4--text">
                    ${{ changeNumberFormat(item.payout) }}
                  </v-card-title>
                  <v-data-table
                    :headers="headers4"
                    :items="investcalc[investname]"
                    :items-per-page="5"
                    class="elevation-1"
                    :custom-sort="customSort"
                  >
                    <template v-slot:item.currentMonthHotspot="{ item }">
                      {{ changeNumberFormat(item.currentMonthHotspot) }}
                    </template>
                    <template v-slot:item.HNTMined="{ item }">
                      {{ changeNumberFormat(item.HNTMined) }}
                    </template>
                    <template v-slot:item.HNTValue="{ item }">
                      {{ changeNumberFormat(item.HNTValue) }}
                    </template>
                    <template v-slot:item.earned="{ item }">
                      {{ changeNumberFormat(item.earned) }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col md="7" cols="12" class="my-2">
          <div id="chart">
            <apexchart
              type="rangeBar"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <v-card class="pa-2" min-height="300px">
            <v-card-title>
              Transfer activity
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="pa-2">
              <v-data-table
                :headers="headers"
                :items="requestLog"
                item-key="name"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:body.append> </template>
                <template v-slot:item.entered="{ item }">
                  <span>{{ formatDate(item.entered) }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>$ {{ changeNumberFormat(item.amount) }} USD </span>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col> </v-row
      ><v-card class="pa-3">
        <v-card-title>Comments </v-card-title>
        <v-row class="fill-height pb-14" align="end">
          <v-col>
            <div
              v-for="(item, index) in chat"
              :key="index"
              :class="[
                'd-flex flex-row align-center my-2',
                item.from == user.data.displayName ? 'justify-end' : null,
              ]"
            >
              <v-avatar
                :color="item.from == user.data.displayName ? 'indigo' : 'red'"
                size="36"
              >
                <span class="white--text">{{ item.from[0] }}</span>
              </v-avatar>

              <span
                v-if="item.from != user.data.displayName"
                class="blue--text ml-3"
                >{{ item.msg }}</span
              >
            </div>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters>
          <v-col>
            <div class="d-flex flex-row align-center">
              <v-text-field v-model="msg" placeholder="Type Something" @keypress.enter="send"></v-text-field>
              <v-btn icon class="ml-4" @click="send"><v-icon>mdi-send</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>-->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { API_BASE_URL, API_HELIUM_URL } from "../../constants";
import moment from "moment";
export default {
  data() {
    return {
      investcalc: {},
      uid: localStorage.getItem('user-portal-uid'),
      haddress: "",
      oracleprice: 0,
      payoutamount: 0,
      payoutdate: new Date(),
      paidout: [],
      paidouttotal: 0,
      previsiontotal: 0,
      loading: true,
      menu1: false,
      tier: null,
      menu2: false,
      signneed: false,
      date: "",
      user: { data: { displayName: "" } },
      status: "not avaiable",
      daterror: true,
      datareverse: true,
      total: 0,
      requestLog: [],
      ownedhotspot: [],
      detailhotspot: [],
      hotspotAll: [],
      installdatetime: "",
      hotspotcount: 0,
      hotspottotalcount: 0,
      totalhotspots: "",
      balance: 0,
      remainbalance: 0,
      arr: {},
      investDet: [],
      todaydate: new Date(),
      hotspotTotal: [],
      installation: 0,
      notamonth: true,
      search: "",
      today: 0,
      first30days: 0,
      commission: 20,
      investor: 0,
      investPart: [],
      hots: [],
      chat: [],

      msg: null,
      series: [],
      chartOptions: {
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return "$" + data.withdrawAmount;
          },
        },
        height: 350,
        type: "rangeBar",

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            var a = val[0];
            var b = val[1];
            var diff = b - a;
            return "$";
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        xaxis: {
          type: "number",
          tickAmount: 3,
          align: "center",
          max: 90,
          labels: {
            formatter: function (val) {
              if (val <= 30) {
                val = "Tier 1";
              } else if (val <= 60) {
                val = "Tier 2";
              } else {
                val = "Lifetime Annuity";
              }
              return val;
            },
            offsetX: 170,
            axisBorder: {
              show: true,
            },
          },
        },
        title: {
          text: "Investment Journey",
          align: "center",
        },
        legend: {
          position: "bottom",
        },
      },
    };
  },
  computed: {
    headers() {
      return [
        { text: "Investment Name", value: "payment" },
        { text: "Amount", value: "amount" },
        {
          text: "Status",
          value: "flag",
        },
        {
          text: "Date Last Update",
          align: "start",
          value: "entered",
        },
      ];
    },
    headers4() {
      return [
        {text: "Month",value: "month"},
        { text: "Total JAG Online Hotspots", value: "currentMonthHotspot" },
        { text: "Total HNT Mined by JAG (Monthly)", value: "HNTMined" },
        { text: "HNT Monthly Average Price (USD)", value: "HNTValue" },
        { text: "Commission in %", value: "commission" },
        { text: "Monthly Earnings (USD)", value: "earned" },
      ];
    },
    monthfirstDay() {
      return new Date(
        this.todaydate.getFullYear(),
        this.todaydate.getMonth() + 1,
        1
      );
    },
  },

  beforeMount() {
    this.getuid();
    this.getInvest();
    this.getWithdraw();
    this.getOracleValue();
    this.getwithdrawal();
    //(this.loading = true);
  },

  methods: {
         customSort(items, index, isDesc) {
      items.sort((a, b) => {
           if (index[0]=='month') {
            var d1 = new Date(a.monthFilter);
            var d2 = new Date(b.monthFilter)
            if (!isDesc[0]) {
                return d1 - d2;
            }
            else {
                return  d2 - d1;
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
               if (!isDesc[0]) {
                  return (a[index[0]] < b[index[0]]) ? -1 : 1;
              } else {
                  return (b[index[0]] < a[index[0]]) ? -1 : 1;
              }
            }
          }
      });
      return items;
    },
    getuid() {
      this.getglobal();

      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            console.log(resp),
              //if(resp.data[0].signed==1){this.signneed=false}
              //else{this.signneed=true}
              (this.investor = resp.data[0].investor);
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
      axios
        .get(`${API_BASE_URL}/user/investor/chat/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          console.log(resp);
          var res = [];
          for (var x in resp.data) {
            res.push({
              from: resp.data[x].name,
              msg: resp.data[x].message,
              datetime: resp.data[x].entered,
            });
          }
          this.chat = res;
        });
    },
    getInvest() {
      axios
        .get(`${API_BASE_URL}/investments/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          var a = resp.data;

          for (var i in a) {
            this.total += parseInt(a[i].amount);
            this.hotspotcount += parseInt(a[i].hotspot);
            this.investDet.push({
              ID: a[i].ID,
              amount: a[i].amount,
              name: a[i].payment,
            });
          }
          this.getJAGstatus();
        });
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    getwithdrawal() {
      axios
        .get(`${API_BASE_URL}/withdrawal/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          var aa = {};
          var paidouttotal = 0;
          var withdrawalLog = resp.data;
          for (var i in withdrawalLog) {
            if (aa[withdrawalLog[i].payment]) {
              console.log("empty");
            } else {
              aa[withdrawalLog[i].payment] = 0;
            }
            aa[withdrawalLog[i].payment] += parseInt(withdrawalLog[i].amount);
            paidouttotal += parseInt(withdrawalLog[i].amount);
            if (withdrawalLog[i].flag == 0) {
              withdrawalLog[i].flag = "Pending";
            } else if (withdrawalLog[i].flag == 1) {
              withdrawalLog[i].flag = "Completed";
            } else if (withdrawalLog[i].flag == 2) {
              withdrawalLog[i].flag = "Deleted";
            } else if (withdrawalLog[i].flag == 3) {
              withdrawalLog[i].flag = "Approved";
            }
          }
          this.gettier(aa);
        });
    },

    async gettier(withdraw) {
      axios
        .get(`${API_BASE_URL}/investments/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then(async (resp) => {
          var total = 0;
          var hotspotcount = 0;
          var investLog = resp.data;
          for (var i in investLog) {
            total += parseInt(investLog[i].amount);
            hotspotcount += parseInt(investLog[i].hotspot);
            if (investLog[i].flag == 0) {
              investLog[i].flag = "Pending";
            } else if (investLog[i].flag == 1) {
              investLog[i].flag = "Completed";
            } else if (investLog[i].flag == 2) {
              investLog[i].flag = "Deleted";
            } else if (investLog[i].flag == 3) {
              investLog[i].flag = "Approved";
            }
          }
          var investname = investLog.map(({ payment }) => payment);
          var tier = {};
          var invest = {};
          for (var y in investLog) {
            invest[investLog[y].payment] = {};
            invest[investLog[y].payment].amount = investLog[y].amount;
            invest[investLog[y].payment].hotspot = investLog[y].hotspot;
            invest[investLog[y].payment].Tier1x = investLog[y].Tier1x;
            invest[investLog[y].payment].Tier3x = investLog[y].Tier3x;
            invest[investLog[y].payment].TierLiftime = investLog[y].TierLiftime;
            invest[investLog[y].payment].customTier = investLog[y].CustomTier;
            invest[investLog[y].payment].entered = investLog[y].entered;
          }
          for (var x in invest) {
            tier[x] = {};
            tier[x]["entered"] = moment(invest[x]["entered"]).format(
              "YYYY-MM-DD"
            );
            console.log("Full details Investment");

            tier[x]["amount"] = parseInt(invest[x]["amount"]);
            tier[x]["hotspot"] = parseInt(invest[x]["hotspot"]);
            tier[x]["withdraw"] = withdraw[x];
            if (parseInt(invest[x]["amount"]) <= withdraw[x]) {
              console.log("1x");
              tier[x]["tier"] = "1x";
              tier[x]["commission"] = invest[x].Tier1x;
              if (withdraw[x] <= parseInt(invest[x]["amount"]) * (parseInt(invest[x]["customTier"])+1)) {
                console.log("3x");
                tier[x]["tier"] = "3x";
                tier[x]["commission"] = invest[x].Tier3x;
              }
              if (withdraw[x] >= parseInt(invest[x]["amount"]) * (parseInt(invest[x]["customTier"])+1)) {
                tier[x]["tier"] = "lifetime";
                tier[x]["commission"] = invest[x].TierLiftime;
              }
            } else {
              tier[x]["tier"] = "1x";
            }
            tier[x]["commission"] = invest[x].Tier1x;
            var investTable = [];
            console.log(tier[x]["entered"])
            var [year, month, day] = tier[x]["entered"].split("-");
            console.log(year, month, day)
            var days = this.daysInMonth(month, year);
            let u = await this.getMonthWiseReward(tier[x]["entered"]);
            if (u.data.length > 0) {
              let amount = 0;
              let current = 0;
              let payout = 0;
              var months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              //payout += tier[x]["withdraw"] ? tier[x]["withdraw"] : 0;
              for (let i = 0; i < u.data.length; i++) {
                current = 0;
                console.log(
                  "month",
                  u.data[i].month,
                  "Total Hotspot",
                  u.data[i].hotspot,
                  "Total Mined",
                  u.data[i].amount
                );
                if (i == 0) {
                  console.log("first month start on",day,"/",days)
                  current =
                    (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                    ((u.data[i].amount)/days*(days-day) / 100) * //this.previsiontotal total of all months amount
                    tier[x]["commission"] *
                    u.data[i].HNTvalue;
                  investTable.push({
                    "monthFilter": (months[u.data[i].month-1]+" "+u.data[i].year), 
                    "month":months[u.data[i].month-1]+ ', '+ u.data[i].year,
                    "Investment Hotspot": tier[x]["hotspot"],
                    currentMonthHotspot: u.data[i].hotspot,
                    HNTMined: parseFloat((u.data[i].amount)/days*(days-day)).toFixed(2),
                    HNTValue: u.data[i].HNTvalue,
                    commission: tier[x]["commission"],
                    earned: current.toFixed(2),
                  });
                } else {
                  current =
                    (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                    (u.data[i].amount / 100) * //this.previsiontotal total of all months amount
                    tier[x]["commission"] *
                    u.data[i].HNTvalue;
                  investTable.push({
                    "monthFilter": (months[u.data[i].month-1]+" "+u.data[i].year), 
                    "month":months[u.data[i].month-1]+ ', '+ u.data[i].year,
                    "Investment Hotspot": tier[x]["hotspot"],
                    currentMonthHotspot: u.data[i].hotspot,
                    HNTMined: parseFloat(u.data[i].amount).toFixed(2),
                    HNTValue: u.data[i].HNTvalue,
                    commission: tier[x]["commission"],
                    earned: current.toFixed(2),
                  });
                }
                console.log(
                  "month",
                  u.data[i].month,
                  "Calculation Formula",
                  "(",
                  tier[x]["hotspot"],
                  "/",
                  u.data[i].hotspot,
                  ")*(",
                  u.data[i].amount,
                  "/100)* ",
                  tier[x]["commission"],
                  "*(",
                  u.data[i].HNTvalue,
                  ")"
                );
                console.log("pre payout:", payout, "pre current", current);
                payout += current;
                console.log("payout:", payout, "current", current);
                amount = this.calctier(
                  invest[x]["amount"],
                  payout,
                  tier[x]["commission"],
                  tier,
                  x,
                  u.data[i].HNTvalue,
                  invest
                ); //remain after passing the tier
                if (amount.remainto > 0) {
                  console.log(
                    "for that month out of the tier: $",
                    amount.remainto
                  );
                  payout -= amount.remainto;
                  current =
                    (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                    (amount.remain / 100) * //this.previsiontotal total of all months amount
                    tier[x]["commission"] *
                    u.data[i].HNTvalue;
                  console.log("Price adjusted on the new tier: $", current);
                  investTable[investTable.length - 1]["HNTMined"] = (
                    investTable[investTable.length - 1]["HNTMined"] -
                    amount.remain
                  ).toFixed(2);
                  investTable[investTable.length - 1]["earned"] = (
                    investTable[investTable.length - 1]["earned"] -
                    amount.remainto
                  ).toFixed(2);
                  investTable.push({ "monthFilter": (months[u.data[i].month-1]+" "+u.data[i].year), 
                    "month":(months[u.data[i].month-1]+ ', '+ u.data[i].year +" (Next Tier)"),
                    "Investment Hotspot": tier[x]["hotspot"],
                    currentMonthHotspot: u.data[i].hotspot,
                    HNTMined: parseFloat(amount.remain).toFixed(2),
                    HNTValue: u.data[i].HNTvalue,
                    commission: tier[x]["commission"],
                    earned: current.toFixed(2),
                  });
                  payout += current;
                }
              }
              tier[x]["amount"] = amount;
              tier[x]["payout"] = (
                payout - (tier[x]["withdraw"] ? tier[x]["withdraw"] : 0)
              ).toFixed(2); //subtract the withdraws
              console.log(
                "total earned:",
                payout,
                "already withdraw:",
                tier[x]["withdraw"] ? tier[x]["withdraw"] : 0
              );
              console.log("remain to Withdraw $", tier[x]["payout"]);
            }
            this.investcalc[x] = investTable;
          }
          this.tier = tier;
        });
    },
    calctier(amountInvested, currentStatus, perc, tier, x, hnt, invest) {
      console.log(
        "total of investment $",
        amountInvested,
        "arrive in calculation $",
        currentStatus,
        "hntValue",
        hnt
      );
      var remain = 0;
      var commission = 0;
      var remainto = 0;
      var HNTvalue = 0;
      if (
        currentStatus <= amountInvested &&
        perc != invest[x].Tier3x &&
        perc != invest[x].TierLiftime
      ) {
        remainto = currentStatus - amountInvested;
        tier[x]["tier"] = "1x";
        tier[x]["commission"] = invest[x].Tier1x;
      } else if (
        currentStatus <= amountInvested * (parseInt(invest[x]["customTier"])+1) &&
        perc != invest[x].Tier3x &&
        perc != invest[x].TierLiftime
      ) {
        remainto = currentStatus - amountInvested;
        HNTvalue = remainto / hnt;
        tier[x]["tier"] = "3x";
        tier[x]["commission"] = invest[x].Tier3x;
        remain = HNTvalue;
      } else if (
        currentStatus >= amountInvested * (parseInt(invest[x]["customTier"])+1) &&
        perc != invest[x].TierLiftime
      ) {
        remainto = currentStatus - amountInvested * (parseInt(invest[x]["customTier"])+1);
        HNTvalue = remainto / hnt;
        tier[x]["tier"] = "lifetime";
        tier[x]["commission"] = invest[x].TierLiftime;
        remain = HNTvalue;
      }
      return { remain: remain, remainto: remainto };
    },
    getMonthWiseReward(entred) {
       let end = new Date().toISOString().slice(0, 10)
      return axios.get(`${API_BASE_URL}/total/reward/${entred}/${end}`, {
        headers: {
          accept: "application/json",
        },
      });
    },

    getWithdraw() {
      axios
        .get(`${API_BASE_URL}/withdrawal/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.requestLog = resp.data;

          for (var i in this.requestLog) {
            this.paidouttotal += parseInt(this.requestLog[i].amount);
            if (this.requestLog[i].flag == 0) {
              this.requestLog[i].flag = "Pending";
            } else if (this.requestLog[i].flag == 1) {
              this.requestLog[i].flag = "Completed";
              this.alreadypayed -= parseFloat(this.requestLog[i].HNTchange);
            } else if (this.requestLog[i].flag == 2) {
              this.requestLog[i].flag = "Deleted";
            } else if (this.requestLog[i].flag == 3) {
              this.requestLog[i].flag = "Approved";
            }
            this.investPart.push([
              this.requestLog[i].payment,
              parseInt(this.requestLog[i].amount),
              parseInt(this.requestLog[i].CustomTier)
            ]);
          }

          this.populateChartTier1(this.investPart);
          this.payoutamount = this.requestLog[i].amount;
          var [year, month, day] = this.requestLog[i].entered
            .substring(0, 10)
            .split("-");
          //this.payoutdate = new Date(year, month, day);
        });
    },
    populateChartTier1(investPart) {
      this.tier = 1;
      var resp = [];
      var out = [];
      var element = {};
      var investentContainer = [];
      for (var x in investPart) {
        if (investentContainer.find(({ name }) => name == investPart[x][0])) {
          var index = investentContainer.findIndex(
            ({ name }) => name == investPart[x][0]
          );
          investentContainer[index].amount += investPart[x][1];
        } else {
          var a = { name: investPart[x][0], amount: investPart[x][1] , customTier: investPart[x][2]};
          investentContainer.push(a);
        }
      }

      investPart = investentContainer.reverse();
      var tier2 = [];
      var tier3 = [];
      for (var i = investPart.length - 1; i >= 0; i--) {
        investPart[i].amount;
        var item = this.investDet.findIndex(
          ({ name }) => name == investPart[i].name
        );
        var value = this.investDet[item].amount;
        if (value < investPart[i].amount) {
          console.log("invest 1x done");
          tier2.push(
            this.populateChartTier2(i, investPart[i].name, investPart[i].amount)
          );
          console.log(i, investPart[i].name, investPart[i].amount, value);
          if (value * (investPart[i].customTier+1) < investPart[i].amount) {
            console.log("invest 3x done");
            console.log(investPart[i].name, investPart[i].amount, value);
            tier3.push(
              this.populateChartTier3(
                i,
                investPart[i].name,
                investPart[i].amount
              )
            );
          }
          investPart[i].amount = value;
        } else {
          tier2.push({
            x: investPart[i].name,
            y: [30, 30],
            withdrawAmount: investPart[i].amount,
          });
          tier3.push({
            x: investPart[i].name,
            y: [60, 60],
            withdrawAmount: investPart[i].amount,
          });
        }

        element = {};
        element.x = investPart[i].name;
        element.withdrawAmount = investPart[i].amount;
        element.y = this.scaleBars(
          i,
          30,
          0,
          investPart[i].amount,
          investPart[i].name,
          0
        );
        out.push(element);
      }
      resp.push(
        { name: "Tier 1", data: out },
        { name: "Tier 2", data: tier2 },
        { name: "Lifetime Annuity", data: tier3 }
      );

      for (let i = 0; i < resp.length; i++) {
        for (let j = 0; j < resp[i].data.length; j++) {
          if (i != 0) {
            resp[i].data[j].withdrawAmount =
              parseInt(resp[i].data[j].withdrawAmount) -
              parseInt(resp[i - 1].data[j].withdrawAmount);
          }
        }
      }

      for (let i = 0; i < resp.length; i++) {
        for (let j = 0; j < resp[i].data.length; j++) {
          resp[i].data[j].withdrawAmount = this.changeNumberFormat(
            resp[i].data[j].withdrawAmount
          );
        }
      }

      console.log("graph", resp);
      this.series = resp;
    },
    scaleBars(index, barEnd, barStart, totalWithdraw, investName, tier) {
      var item = this.investDet.findIndex(({ name }) => name == investName);
      var value = this.investDet[item].amount;
      if (tier == 2) {
        value = value * 2;
        totalWithdraw = totalWithdraw - this.investDet[item].amount;
        if (totalWithdraw >= value) {
          totalWithdraw = value;
        }
      }
      if (tier == 3) {
        value = value * 3;
        totalWithdraw = totalWithdraw - this.investDet[item].amount;
      }
      console.log(totalWithdraw, value);
      var result = ((barEnd - barStart) * totalWithdraw) / value + barStart;
      console.log(result);
      return [barStart, result];
      //this.series[tier].data[index].y=[barStart,result]
    },
    populateChartTier2(i, name, amount) {
      console.log("start next investment tier");
      this.tier = 2;
      var aa = {
        x: name,
        y: this.scaleBars(i, 60, 30, amount, name, 2),
        withdrawAmount: amount,
      };
      return aa;
    },
    populateChartTier3(i, name, amount) {
      console.log("start next investment tier");
      this.tier = 3;
      var aa = {
        x: name,
        y: this.scaleBars(i, 90, 60, amount, name, 3),
        withdrawAmount: amount,
      };
      return aa;
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data.data.price;
        });
    },
    parsingName(arr3) {
      var arr1 = [];
      var arr2 = [];
      let promises = [];
      arr3.forEach((element) => {
        promises.push(
          axios.get(`${API_HELIUM_URL}/v1/hotspots/${element.Haddress}`, {
            headers: { accept: "application/json" },
          })
        );
      });
      Promise.all(promises).then((results) => {
        results.forEach((resp) => {
          arr2.push(resp.data.data);
          arr1.push(resp.data.data.name);
        });
        this.remainpayment(arr1, arr2, arr3);
      });
    },
    remainpayment(arr1, arr2, arr3) {
      this.ownedhotspot = arr1; //name hotspot
      this.detailhotspot = arr2; //helium data
      this.hotspotAll = arr3; //jag api data
      var i;
      var app;
      var conc = {};
      let multipleSum = 0;
      let details = this.detailhotspot;
      this.remainbalance = 0;
      for (i = 0; i < details.length; i++) {
        for (var attrname in this.hotspotAll[i]) {
          conc[attrname] = this.hotspotAll[i][attrname];
        }
        for (var attrname1 in details[i]) {
          conc[attrname1] = details[i][attrname1];
        }
        this.hotspotAll[i] = conc;
        conc = {};
      }
      this.hotspotAll.forEach((element) => {
        //jag value + helium value
        var endinst = null;
        var [year, month, day] = element.timestamp_added
          .substring(0, 10)
          .split("-");

        var installation = new Date(year, month - 1, day);

        if (element.installDate != null) {
          this.installdatetime = element.installDate.slice(0, 10);
        } else {
          this.installdatetime = installation.toISOString().substring(0, 10);
        }
        if (element.removeDate != null) {
          endinst = "&max_time=" + element.removeDate.slice(0, 10);
        }
        this.installdatetime = this.checkfirstdays(
          element.role,
          this.installdatetime,
          element.firstdays
        );
        if (element.role == 1) {
          //if refer owner
          if (element.firstdays == 0) {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              "refer"
            );
          } else {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              "refer"
            );
          }
        }
        if (element.role == 0) {
          //if primary owner
          if (this.first30days == 1) {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              "primary"
            );
          } else {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              "primary"
            );
          }
        }
      });
    },

    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      var a = new Date();
      var firstDayMonth = new Date(a.getFullYear(), a.getMonth(), 1);
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          //installation = new Date(year, month - 1, day);
          installation.setDate(firstDayMonth.getDate());
        } else {
          //30 days YES
          //installation = new Date(year, month, day);
          installation.setDate(firstDayMonth.getDate());
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          //installation = new Date(year, month, day);
          installation.setDate(firstDayMonth.getDate());
        } else {
          //installation = new Date(year, month - 1, day);
          installation.setDate(firstDayMonth.getDate());
        }
      }

      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },

    getJAGstatus() {
      axios
        .get(`${API_BASE_URL}/total/reward`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspottotalcount = resp.data.hotspot;
          this.previsiontotal = resp.data.balance;
        });
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            //if(resp.data[0].signed==1){this.signneed=false}
            //else{this.signneed=true}
            (this.commission = resp.data[0].commission),
              (this.first30days = resp.data[0].FirstDays);
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
    },
    getbalance(address, dateinst, endinst, commission, type) {
      var final_comm = 0;
      commission = this.commission;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            //if (resp.data.length != 0) {
            //  final_comm = resp.data[0].commission;
            //} else {
            //  final_comm = 20;
            //}
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.remainbalance +=
            ((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission;
          // console.log( "total",resp.data.data.total,"final comm",final_comm,"commisison",commission,"addr",address);
        })
        .catch((resp) => {
          this.remainbalance += 0;
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${month}/${day}/${year}`;
    },
    changeNumberFormat(value) {
      if (value && !isNaN(value) && value != 0)
        return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return value;
    },
  },
};
</script>

